<template>
  <div class="premium-and-discount">
    <take-up-premium-discount-management-list
      v-if="metadata && isListing"
      :metadata="metadata"
      :get-items="getItems"
      :validation-schema="validationSchema"
      :count-items="countItems"
      :translate="translate"
      @import="onImport"
    ></take-up-premium-discount-management-list>
    <cg-Crud-Import-Custom-With-Alert
      v-else-if="isImporting"
      :name="name"
      :label="translate(`${name}.title`)"
      :service="service"
      :translate="translate"
      :try-translate-other-locale="tryTranslateOtherLocale"
      @close="onNavigateToList"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcCrudImport, generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'
import { CgCrudFilterSetPd, CgCrudFilterSet } from '@cargill/shared'
import service from '../api/takeUpPremiumDiscountManagementService'
import { TakeUpPremiumDiscountManagementList } from '../components/take-up-premium-discount-management'

import {
  CgCrudImportCustomWithAlert,
} from '@cargill/shared'

function debounceAsync(fn, timeoutInMs = 200) {
  const debouncedWaitList = new Map()

  return async (...args) =>
    new Promise((resolve, reject) => {
      const key = JSON.stringify(args)
      const waitList = debouncedWaitList.get(key) ?? { promises: [] }
      clearTimeout(waitList.timeout)

      debouncedWaitList.set(key, waitList)
      waitList.promises.push({ resolve, reject })
      waitList.timeout = setTimeout(() => {
        try {
          const result = fn(...args)
          debouncedWaitList.delete(key)
          waitList.promises.forEach(({ resolve }) => resolve(result))
        } catch (error) {
          debouncedWaitList.delete(key)
          waitList.promises.forEach(({ reject }) => reject(error))
        }
      }, timeoutInMs)
    })
}

const ViewMode = {
  LISTING: 'List',
  IMPORTING: 'Import'
}

export default defineComponent({
  name: 'TakeUpPremiumDiscountManagement',
  components: {
    BcCrudImport,
    CgCrudImportCustomWithAlert,
    TakeUpPremiumDiscountManagementList
  },
  data() {
    return {
      name: 'takeUpPremiumDiscountManagement',
      service,
      mode: ViewMode.LISTING,
      metadata: null,
      getItems: debounceAsync(service.getAll),
      countItems: debounceAsync(service.getCount)
    }
  },
  created() {
    this.loadMetadata()
  },
  methods: {
    async loadMetadata() {
      this.$route.params.name = this.name
      this.metadata = await service.getMetaWithValidation()
      this.metadata.fields.push({
        editable: false,
        gridTarget: 'managerial',
        id: 'premiumDiscountActions',
        key: 'premiumDiscountActions',
        propertyType: 'string',
        type: 'text',
        cellRenderer: 'premiumDiscountActions'
      })
      this.metadata.fields.forEach((field) => {
        if (field.filterFramework === CgCrudFilterSet) {
          field.filterFramework = CgCrudFilterSetPd
        }
      })
      this.validationSchema = yup
        .object()
        .shape(
          generateFieldsSchema(this.metadata, (key) =>
            this.translate(`${this.metadata.id}.${key}`)
          )
        )
    },
    translate(key) {
      return this.$t(`controlTower.pages.${key}`)
    },
    tryTranslateOtherLocale(key) {
      const locales = Object.keys(this._i18n.messages)
      if (!locales) return []
      let translates = locales
        .filter((x) => x != this._i18n.locale)
        .map((locale) => {
          return this.translate(key, locale)
        })
      return translates
    },
    onNavigateToList() {
      this.mode = ViewMode.LISTING
    },
    onImport() {
      this.mode = ViewMode.IMPORTING
    }
  },
  computed: {
    isImporting() {
      return this.mode === ViewMode.IMPORTING
    },
    isListing() {
      return this.mode === ViewMode.LISTING
    }
  }
})
</script>

<style lang="scss" scoped>
.premium-and-discount {
}
</style>

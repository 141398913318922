<template>
  <bc-container class="premium-discount-list">
    <div class="main-toolbar">
      <bc-btn color="link-gray" class="mr-2" @click="onExportForProducer">
        {{
          $t(
            'controlTower.pages.takeUpPremiumDiscountManagement.exportForProducer'
          )
        }}
      </bc-btn>
      <template v-if="canUpdate">
        <cg-btn-dropdown @click="onPreviewWeightedAverage">
          {{
            $t(
              'controlTower.pages.takeUpPremiumDiscountManagement.previewWeightedAverage'
            ) +
            ' ' +
            weightedAverageStr
          }}

          <template v-slot:options>
            <bc-btn @click="onApplyWeightedAverage">
              {{
                $t(
                  'controlTower.pages.takeUpPremiumDiscountManagement.applyWeightedAverage'
                )
              }}
            </bc-btn>
            <bc-btn @click="onClearWeightedAverage">
              {{
                $t(
                  'controlTower.pages.takeUpPremiumDiscountManagement.clearWeightedAverage'
                )
              }}
            </bc-btn>
          </template>
        </cg-btn-dropdown>
      </template>
      <template v-else>
        <bc-btn color="primary" @click="onPreviewWeightedAverage">
          {{
            $t(
              'controlTower.pages.takeUpPremiumDiscountManagement.previewWeightedAverage'
            ) +
            ' ' +
            weightedAverageStr
          }}
        </bc-btn>
      </template>
    </div>

    <cargill-section>
      <cargill-section-header class="premium-discount-list__header">
        <div class="premium-discount-list__header">
          {{ $t('controlTower.pages.takeUpPremiumDiscountManagement.title') }}
        </div>

        <div class="premium-discount-list__toolbar">
          <div class="premium-discount-list__page-size">
            <label
              for="page-size"
              class="premium-discount-list__page-size-label"
            >
              {{
                $t(
                  'controlTower.pages.takeUpPremiumDiscountManagement.showLines'
                )
              }}
            </label>
            <bc-select
              id="page-size"
              class="premium-discount-list__page-size-select"
              v-model="pageSizeInput"
              @change="onPageSizeChange"
              embedded
              :items="[5, 25, 50, 100, 200, 300]"
            ></bc-select>
          </div>

          <cargill-section-header-btn
            icon="fa-redo"
            :title="$t('core.misc.refresh')"
            @click="onReload"
          >
          </cargill-section-header-btn>

          <cargill-section-header-btn
            v-if="canUpdate"
            icon="fa-calculator"
            :title="
              $t(
                'controlTower.pages.takeUpPremiumDiscountManagement.calculatePremiumDiscount'
              )
            "
            @click="onCalculatePremiumDiscount"
          >
          </cargill-section-header-btn>

          <cargill-section-header-btn
            icon="fa-table"
            :title="
              $t(
                'controlTower.pages.takeUpPremiumDiscountManagement.hviExtract'
              )
            "
            @click="hviExtract"
          >
          </cargill-section-header-btn>

          <cargill-section-header-btn
            v-if="canUpdate"
            icon="fa-upload"
            :title="$t('core.misc.import')"
            @click="onImport"
          >
          </cargill-section-header-btn>

          <cargill-section-header-btn
            icon="fa-download"
            :title="$t('core.misc.export')"
            @click="onExport"
          >
          </cargill-section-header-btn>
        </div>
      </cargill-section-header>

      <cargill-section-content>
        <cargill-section layer="3" class="premium-discount-list__managerial">
          <cargill-section-header
            class="premium-discount-list__managerial__header"
          >
            {{
              $t(
                'controlTower.pages.takeUpPremiumDiscountManagement.managerial'
              )
            }}
          </cargill-section-header>
          <cargill-section-content>
            <take-up-premium-discount-management-meta-grid
              ref="managerialGrid"
              target="managerial"
              :metadata="metadata"
              :service="service"
              :get-items="getItems"
              :count-items="countItems"
              :page-size="pageSize"
              :translate="translate"
              :modified-cells="modifiedCells"
              :validation-result="validationResult"
              :editable="canUpdate"
              :filter="filter"
              :sort="sort"
              :page="page"
              @filter-changed="onFilterChanged"
              @sort-changed="onSortingChanged"
              @page-changed="onPageChanged"
              @cell-changed="onCellChanged"
            ></take-up-premium-discount-management-meta-grid>
          </cargill-section-content>
        </cargill-section>

        <cargill-section layer="3" class="premium-discount-list__detailing">
          <cargill-section-header
            class="premium-discount-list__detailing__header"
          >
            {{
              $t('controlTower.pages.takeUpPremiumDiscountManagement.detailing')
            }}
          </cargill-section-header>
          <cargill-section-content>
            <take-up-premium-discount-management-meta-grid
              ref="detailingGrid"
              target="detailing"
              :metadata="metadata"
              :service="service"
              :get-items="getItems"
              :count-items="countItems"
              :page-size="pageSize"
              :translate="translate"
              :modified-cells="modifiedCells"
              :validation-result="validationResult"
              :editable="canUpdate"
              :filter="filter"
              :sort="sort"
              :page="page"
              @filter-changed="onFilterChanged"
              @sort-changed="onSortingChanged"
              @page-changed="onPageChanged"
              @cell-changed="onCellChanged"
            ></take-up-premium-discount-management-meta-grid>
          </cargill-section-content>
        </cargill-section>

        <div v-if="hasChanges" class="premium-discount-list__editing-actions">
          <bc-btn color="link-gray" class="mr-4" @click="onCancelEditing">
            {{ $t('application.actions.cancel') }}
          </bc-btn>
          <bc-btn color="primary" @click="onSaveEditing">
            {{ $t('application.actions.save') }}
          </bc-btn>
        </div>
      </cargill-section-content>
    </cargill-section>
  </bc-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer, helpers } from '@brain/core'
import FileDownload from 'js-file-download'
import { useTransactions, eventHub } from '@cargill/shared'
import _ from 'lodash'

import service from '../../api/takeUpPremiumDiscountManagementService'
import serviceReturn from '../../api/takeUpReturnsAndClaimsMaster'
import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent,
  CargillSectionHeaderBtn
} from '../cargill-section'
import { CgBtnDropdown } from '../cg-btn-dropdown'
import downloadFile from 'js-file-download'

import TakeUpPremiumDiscountManagementMetaGrid from './TakeUpPremiumDiscountManagementMetaGrid.vue'

export default defineComponent({
  name: 'PremiumDiscountList',
  components: {
    BcContainer,
    CgBtnDropdown,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    CargillSectionHeaderBtn,
    TakeUpPremiumDiscountManagementMetaGrid
  },
  props: {
    metadata: { type: Object, required: true },
    validationSchema: { type: Object, required: true },
    getItems: { type: Function, required: true },
    countItems: { type: Function, required: true },
    translate: { type: Function, required: true }
  },
  data() {
    return {
      service,
      serviceReturn,
      weightedAverage: null,
      trackWeightedAverage: false,
      pageSize: 5,
      pageSizeInput: 5,
      filter: {},
      sort: [],
      page: 0,
      modifiedCells: {},
      modifiedRows: {},
      validationResult: {},
      transactions: useTransactions()
    }
  },
  mounted() {
    this.reloadDataFunc = () => this.clearAndReload()
    eventHub.$on('reload-take-up-premium-discount-data', this.reloadDataFunc)
  },
  beforeDestroy() {
    eventHub.$off('reload-take-up-premium-discount-data', this.reloadDataFunc)
  },
  methods: {
    clearAndReload() {
      this.modifiedCells = {}
      this.modifiedRows = {}
    },
    async confirmCancelEditing() {
      return new Promise((resolve) => {
        if (Object.keys(this.modifiedCells).length === 0) {
          return resolve(true)
        }

        this.$brain.confirm(
          {
            title: this.$t('core.crud.attention'),
            subtitle: this.$t(
              'controlTower.pages.takeUpPremiumDiscountManagement.unsavedChangesMessage'
            )
          },
          () => resolve(true),
          () => resolve(false)
        )
      })
    },
    async confirmconfirmDisapprovalPd() {
      return new Promise((resolve) => {
        if (Object.keys(this.modifiedCells).length === 0) {
          return resolve(true)
        }

        this.$brain.confirm(
          {
            title: this.$t('core.crud.attention'),
            subtitle: this.$t(
              'controlTower.pages.takeUpPremiumDiscountManagement.unsavedChangesMessage'
            )
          },
          () => resolve(true),
          () => resolve(false)
        )
      })
    },
    async confirmPromise(config, bypassFunc) {
      return new Promise((resolve) => {
        if (bypassFunc != null && bypassFunc()) {
          return resolve(true)
        }
        this.$brain.confirm(
          config,
          () => resolve(true),
          () => resolve(false)
        )
      })
    },
    async confirmApplyWeightedAverageCompleted() {
      let confirm = await this.confirmCancelEditing()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmApplyWeightedAverage()
        if (confirm) {
          await new Promise((x) => setTimeout(x))
          confirm = await this.confirmApplyWeightedAverageOnAllItems()
        }
      }
      return confirm
    },
    async confirmApplyWeightedAverage() {
      return this.confirmPromise({
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpPremiumDiscountManagement.confirmApplyWeightedAverage'
        )
      })
    },
    async confirmApplyWeightedAverageOnAllItems() {
      return this.confirmPromise(
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpPremiumDiscountManagement.confirmApplyWeightedAverageOnAllItems'
          )
        },
        () =>
          this.service.hasFilterApplied == null ||
          this.service.hasFilterApplied()
      )
    },
    async confirmClearWeightedAverageCompleted() {
      let confirm = await this.confirmCancelEditing()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmClearWeightedAverage()
        if (confirm) {
          await new Promise((x) => setTimeout(x))
          confirm = await this.confirmClearWeightedAverageOnAllItems()
        }
      }
      return confirm
    },
    async confirmClearWeightedAverage() {
      return this.confirmPromise({
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpPremiumDiscountManagement.confirmClearWeightedAverage'
        )
      })
    },
    async confirmClearWeightedAverageOnAllItems() {
      return this.confirmPromise(
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpPremiumDiscountManagement.confirmClearWeightedAverageOnAllItems'
          )
        },
        () =>
          this.service.hasFilterApplied == null ||
          this.service.hasFilterApplied()
      )
    },
    async confirmCalculatePremiumDiscountCompleted() {
      let confirm = await this.confirmCancelEditing()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmCalculatePremiumDiscount()
        if (confirm) {
          await new Promise((x) => setTimeout(x))
          confirm = await this.confirmCalculatePremiumDiscountOnAllItems()
        }
      }
      return confirm
    },
    async confirmCalculatePremiumDiscount() {
      return this.confirmPromise({
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpPremiumDiscountManagement.confirmCalculatePremiumDiscount'
        )
      })
    },
    async confirmCalculatePremiumDiscountOnAllItems() {
      return this.confirmPromise(
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpPremiumDiscountManagement.confirmCalculatePremiumDiscountOnAllItems'
          )
        },
        () =>
          this.service.hasFilterApplied == null ||
          this.service.hasFilterApplied()
      )
    },
    managerialGrid() {
      return this.$refs.managerialGrid
    },
    detailingGrid() {
      return this.$refs.detailingGrid
    },
    onFilterChanged(filterModel) {
      this.filter = filterModel

      if (this.trackWeightedAverage) {
        this.onPreviewWeightedAverage()
      }
    },
    onSortingChanged(sortModel) {
      this.sort = sortModel
    },
    onPageChanged(page) {
      this.page = page
    },
    async onPageSizeChange(pageSize) {
      const confirm = await this.confirmCancelEditing()
      if (confirm) {
        this.pageSize = pageSize
        this.page = 0
        setTimeout(() => this.clearAndReload())
      } else {
        this.pageSizeInput = this.pageSize
      }
    },
    async onReload() {
      if (await this.confirmCancelEditing()) {
        this.clearAndReload()
      }
    },
    async onExportForProducer() {
      const name = this.$t(
        'controlTower.pages.takeUpPremiumDiscountManagement.title'
      )
      const result = await this.service.exportProducer(this.filterQuery)
      FileDownload(result.data, result.filename ?? `${name}.xlsx`)
    },
    async onExport() {
      const name = this.$t(
        'controlTower.pages.takeUpPremiumDiscountManagement.title'
      )
      const result = await this.service.export(this.filterQuery)
      FileDownload(result.data, result.filename ?? `${name}.xlsx`)
    },
    async onImport() {
      if (await this.confirmCancelEditing()) {
        this.$emit('import')
      }
    },
    async onCancelEditing() {
      if (await this.confirmCancelEditing()) {
        this.clearAndReload()
      }
    },
    async confirmStatusChange(entities, entitiesAprooved) {
      const fieldsWithStatusChange =
        await this.service.validateFieldsWithStatusChange(entities)

      const messageArg = fieldsWithStatusChange.map((x) => `'${x}'`).join(', ')
      const messageKey =
        fieldsWithStatusChange.length > 1
          ? 'controlTower.pages.takeUpPremiumDiscountManagement.confirmFieldsStatusChange'
          : 'controlTower.pages.takeUpPremiumDiscountManagement.confirmFieldStatusChange'
      const messageReturn =
        fieldsWithStatusChange.length > 0 && entitiesAprooved.length > 0
          ? ' Podem haver alguns sinistros que serão apagados com essa modificação.'
          : ''

      return this.confirmPromise(
        {
          title: this.$t('core.crud.attention'),
          subtitle: `${this.$t(messageKey, [messageArg])}${this.$t(
            messageReturn
          )}`
        },
        () => _.isEmpty(fieldsWithStatusChange)
      )
    },
    async confirmAlert(entities) {
      const fieldsWithAlert = await this.service.validateAlert(entities)
      const messageArg = fieldsWithAlert.map((x) => `'${x}'`).join(', ')
      const messageKey =
        fieldsWithAlert.length > 1
          ? 'application.misc.alertMessage'
          : 'application.misc.alertMessage'
      return this.confirmPromise(
        {
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(messageKey, [messageArg])
        },
        () => _.isEmpty(fieldsWithAlert)
      )
    },
    async onSaveEditing() {
      const fields = new Set(this.metadata.fields.map((x) => x.id))
      fields.add('id')
      fields.add('hasTakeUpReturnsAndClaims')
      const removeInvalidFields = (dto) => {
        const newDto = {
          ...dto
        }
        Object.keys(newDto)
          .filter((x) => !fields.has(x))
          .forEach((x) => delete newDto[x])
        return newDto
      }
      const entities = Object.entries(this.modifiedRows).map(
        ([rowId, dto]) => ({
          rowId: rowId,
          dto: removeInvalidFields(dto)
        })
      )

      const entitiesAprooved = entities.filter(
        (item) =>
          item.dto.lotStatus === 'approved' &&
          item.dto.hasTakeUpReturnsAndClaims
      )
      if (
        (await this.confirmStatusChange(entities, entitiesAprooved)) &&
        (await this.confirmAlert(entities))
      ) {
        if (entitiesAprooved.length > 0) {
          this.onExportReturn(entitiesAprooved)
        }

        const result = await this.service.batchUpdate(entities)

        if (Object.keys(result?.errors ?? {}).length) {
          this.validationResult = { errors: result.errors }
          this.notify.error({
            title: this.$t('error.saveUpdateError')
          })
        } else {
          eventHub.$emit('reload-crud-filters')
          this.clearAndReload()
          if (this.trackWeightedAverage) {
            this.onPreviewWeightedAverage()
          }
          const messageUpdateSuccessKey =
            entities.length > 1
              ? 'application.misc.inlineMultiUpdateSuccess'
              : 'application.misc.inlineUpdateSuccess'
          this.notify.success({
            title: this.$t(messageUpdateSuccessKey)
          })
        }
      }
    },
    onCellChanged({ rowId, field, newValue, row }) {
      this.modifiedCells = {
        ...this.modifiedCells,
        [rowId]: {
          ...(this.modifiedCells[rowId] || {}),
          [field]: newValue
        }
      }
      this.modifiedRows[rowId] = row
    },
    async onExportReturn(entities) {
      const tabName = this.$t(
        'controlTower.pages.takeUpReturnsAndClaimsMaster.title'
      )
      const result = await this.serviceReturn.validateFieldsWithStatusChange(
        entities
      )
      downloadFile(result.data, result.filename ?? `${tabName}.xlsx`)
    },
    async onPreviewWeightedAverage() {
      this.weightedAverage = await this.service.previewWeightedAverage(
        this.filterQuery
      )
      this.trackWeightedAverage = true
    },
    async onApplyWeightedAverage() {
      if (await this.confirmApplyWeightedAverageCompleted()) {
        await this.service.applyWeightedAverage(this.filterQuery)
        this.clearAndReload()
      }
    },
    async onClearWeightedAverage() {
      if (await this.confirmClearWeightedAverageCompleted()) {
        await this.service.clearWeightedAverage(this.filterQuery)
        this.clearAndReload()
        if (this.trackWeightedAverage) {
          this.onPreviewWeightedAverage()
        }
      }
    },
    async onCalculatePremiumDiscount() {
      if (await this.confirmCalculatePremiumDiscountCompleted()) {
        const results = await this.service.calculatePremiumDiscountByFilter(
          this.filterQuery
        )
        if (results.some((x) => x.alertsQty > 0)) {
          const rowsWithAlerts = results.filter((x) => x.alertsQty > 0).length
          const rowsWithoutAlerts = results.filter(
            (x) => x.alertsQty == 0
          ).length

          this.notify.warning({
            title: this.$t(
              'controlTower.pages.takeUpPremiumDiscountManagement.premiumDiscountCalculateResultsWithAlerts',
              [rowsWithAlerts, rowsWithoutAlerts]
            )
          })
        } else {
          this.notify.success({
            title: this.$t('application.misc.success')
          })
        }
        this.clearAndReload()
      }
    },
    async hviExtract() {
      const tabName = this.$t(
        'controlTower.pages.takeUpPremiumDiscountManagement.hviExtract'
      )
      const result = await service.hviExtract(tabName)
      downloadFile(result.data, result.filename ?? `${tabName}.xlsx`)
    }
  },
  computed: {
    canUpdate() {
      return this.transactions.canUpdate(this.metadata.id)
    },
    hasChanges() {
      return Object.keys(this.modifiedCells).length > 0
    },
    weightedAverageStr() {
      return this.weightedAverage !== null
        ? Number(this.weightedAverage).toFixed(2)
        : ''
    },
    filterQuery() {
      return helpers.buildQueryParams(
        helpers.buildQueryObject({ filterModel: this.filter })
      )
    }
  }
})
</script>

<style lang="scss" scoped>
.premium-discount-list {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 16px !important;
  }

  &__detailing {
    margin-top: 20px;
  }

  &__editing-actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }

  &__toolbar {
    display: flex;
    align-items: center;
  }

  &__page-size {
    display: flex;
    align-items: center;
    background: #1f2126;
    margin: -8px 10px -8px 0;
  }

  &__page-size-label {
    padding: 8px 0 8px 15px;
    font-weight: normal;
    cursor: pointer;
  }

  &__page-size-select {
    width: 80px;
    padding: 0;
    margin: 0;

    ::v-deep {
      .v-text-field__details {
        display: none;
      }
      .v-input__control .v-input__slot {
        &::before,
        &::after {
          content: none;
        }
      }

      .v-select__selections {
        justify-content: flex-end;
        .v-select__selection {
          margin: 9px 4px 5px;
        }
      }

      input {
        max-width: 0px;
        padding: 0;
      }
    }
  }
}
</style>
